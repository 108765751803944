import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "vant/lib/index.css";
import { Cell, CellGroup, Collapse, CollapseItem, Swipe, SwipeItem } from 'vant';
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Swipe);
Vue.use(SwipeItem);


Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
